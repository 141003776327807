import React from 'react';

import styles from './OptionBlock.module.scss';

type OptionBlockArgs = {
  option: {
    value: string;
    icon?: {
      localFile: {
        publicURL: string;
      };
    };
    title?: string;
  };
};

export function OptionBlock({ option }: OptionBlockArgs) {
  return (
    <div className={styles.optionBlock}>
      <div className={styles.title}>{option.title}</div>
      <div className={styles.option}>
        {option.icon && (
          <img
            className={styles.image}
            src={option.icon?.localFile.publicURL}
            alt="Иконка варианта"
          />
        )}
        {option.value}
      </div>
    </div>
  );
}
