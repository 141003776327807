import React, { useMemo } from 'react';

import { addDays, format } from 'date-fns';
import { Paragraph as P } from '@periodica/ui-kit';
import { ru } from 'date-fns/locale';
import { currency } from '@utils/numbers';
import { Link } from '@periodica/ui-kit';
import { type DialogStore } from '@ariakit/react';

import { RubleSign } from '@components/Blocks/RubleSign';

import * as Modal from '@periodica/ui-kit/modal';

import * as styles from './SplitModal.module.css';

interface SplitModalArgs {
  dialogStore: DialogStore;
  splitSum: number;
}

export function SplitModal({ dialogStore, splitSum }: SplitModalArgs) {
  const splitDates = useMemo(() => {
    const dates: string[] = ['Сегодня'];
    for (let i = 1; i < 4; i += 1) {
      const currentDate = new Date();
      const date = addDays(currentDate, 14 * i);
      const formattedDate = format(date, 'd MMM', { locale: ru }).replace('.', '');
      dates.push(formattedDate);
    }

    return dates;
  }, []);

  return (
    <Modal.Wrapper size="small" store={dialogStore}>
      <Modal.Header title="Оплатите 25% от стоимости покупки" />
      <Modal.Content>
        <div className={styles.mainContent}>
          <P size="large" className={styles.textColor}>
            Оставшиеся 3 части спишутся автоматически с шагом в две недели. Без процентов и
            комиссий, как обычная оплата картой.
          </P>

          <P size="large" className={styles.textColor}>
            График платежей:
          </P>

          <div className={styles.splitGraph}>
            {splitDates.map((splitDate) => (
              <div className={styles.splitGraphItem} key={splitDate}>
                <p className={styles.splitGraphItemTitle}>{splitDate}</p>
                <p className={styles.splitGraphItemPrice}>
                  {currency(splitSum)}&nbsp;
                  <RubleSign />
                </p>
              </div>
            ))}
          </div>
          <div className={styles.info}>
            Подробнее о сервисе можно узнать на{' '}
            <Link href="https://dolyame.ru" target="_blank" rel="noreferrer">
              dolyame.ru
            </Link>
          </div>
        </div>
      </Modal.Content>
    </Modal.Wrapper>
  );
}
