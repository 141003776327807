import React, { useState, useCallback, useMemo, useRef } from 'react';
import { navigate } from 'gatsby';

import { Labels } from '@components/Blocks/Labels';
import { event } from '@analytics/gtm';

import { findCountable } from '@utils/countable';

import { MiniServices } from '@components/Blocks/MiniServices';
import { DeliveryWidget } from '@components/Delivery/DeliveryWidget';

import { SplitButton } from '@components/PageComponents/SplitButton/SplitButton';
import type { MetadataArgs } from '@customTypes/product';

import {
  StickyDesktopInfo,
  ColorPicker,
  OptionsPicker,
  OptionBlock,
  BuyButton,
  OrderServiceButton,
  ProductInfo,
  Promos,
} from './components';

import styles from './Metadata.module.scss';

const MIN_PHOTO_COEFFICIENT = 1;
const MAX_PHOTO_COEFFICIENT = 1.75;

const getCountableType = (productType: string) => {
  switch (productType) {
    case 'prints':
    case 'stickers':
      return 'shots';
    case 'postcard':
    case 'wrappingPaper':
      return 'items';
    default:
      return 'pages';
  }
};

export function Metadata({ product, onPurchaseClick }: MetadataArgs) {
  const initialProductPageCount = useMemo(() => product.options.pageOptions[0].value, [product]);
  const initialProductSize = useMemo(() => product.options.pageOptions[0].productSize, [product]);

  const initialProductPrice = useMemo(() => product.options.pageOptions[0].price, [product]);
  const initialProductSalePrice = useMemo(
    () => product.options.pageOptions[0].optionSalePrice,
    [product]
  );

  const [optionPrice, setOptionPrice] = useState(initialProductPrice);
  const [optionSalePrice, setOptionSaleprice] = useState(initialProductSalePrice);

  const [productUrl, setUrl] = useState(product?.actionUrl);
  const [pageCount, setPageCount] = useState(initialProductPageCount);
  const [productSize, setProductSize] = useState(initialProductSize);

  const targetRef = useRef<HTMLDivElement | null>(null);

  const splitPrice = useMemo(() => {
    if (!!optionSalePrice && optionSalePrice !== optionPrice) {
      return Math.ceil(optionSalePrice / 4);
    }
    return Math.ceil(optionPrice / 4);
  }, [optionPrice, optionSalePrice]);

  const handlePageOptionChange = (option: any) => {
    if (product.options.pageOptions.length === 1) {
      return;
    }

    setOptionPrice(option.price);
    setOptionSaleprice(option.optionSalePrice);
    setUrl(option.actionUrl);
    setPageCount(option.value);
    setProductSize(option.productSize);
  };

  const handleRedirectToPreorder = useCallback(() => {
    navigate('/products/preorder', {
      state: {
        price: optionPrice,
        realPrice: optionSalePrice || optionPrice,
        name: product.title,
      },
    });
  }, [product, optionPrice, optionSalePrice]);

  const handleRedirectToGift = useCallback(() => {
    event('event_productcard_to_gift_top');
    navigate('/products/gift', { state: { sum: { sum: optionPrice } } });
  }, [optionPrice]);

  const handleRedirectToDesign = useCallback(() => {
    event('event_productcard_to_sfvz_top');
    navigate('/design-photobook#form', { state: { product: { title: product.title, pageCount } } });
  }, [product?.title, pageCount]);

  const handleRedirectToConstructor = useCallback(() => {
    onPurchaseClick();
  }, [onPurchaseClick]);

  const scrollToTarget = (e: any) => {
    event('event_productcard_scroll_to_calculator');
    e.preventDefault();
    document.getElementById('delivery-widget')?.scrollIntoView({ behavior: 'smooth' });
  };

  const sizeStr =
    product.options?.anyStringOptions.length &&
    product.options?.anyStringOptions.find((option) => option.optionId === 'size')?.value;

  const productInfoSubtitle = `${findCountable({
    value: pageCount,
    type: getCountableType(product?.productType),
  })}${sizeStr ? `, размер ${sizeStr} см` : ''}`;

  const numberOfPhotosOptions = {
    title: 'Идеальное кол-во фото',
    value: `от ${Math.ceil(+pageCount * MIN_PHOTO_COEFFICIENT)} до ${Math.ceil(+pageCount * MAX_PHOTO_COEFFICIENT)}`,
    id: 'photos',
  };

  const showDeliveryCalculator = !product.preOrder && !product.isUnavailable && productSize;

  return (
    <div className={styles.metadata}>
      <div className={styles.wrapper}>
        <Labels labels={product.labels} />
        <h1 className={styles.header}>{product.title}</h1>
        {!!product.colorVariants.length && (
          <div className={styles.variantColor}>
            <div className={styles.title}>Цвет обложки</div>
            <ColorPicker colors={product.colorVariants} />
          </div>
        )}
        <div className={styles.variants}>
          {!!product.options?.pageOptions?.length && (
            <OptionsPicker
              options={product.options.pageOptions}
              pickerTitle={product.options.pageOptions[0].title || 'Количество страниц'}
              onChange={handlePageOptionChange}
              productUrl={productUrl}
              isOptionSelectable
            />
          )}
          {!!product.options?.miscOptions?.length && (
            <OptionsPicker
              options={product.options.miscOptions}
              pickerTitle={product.options.miscOptions[0].title}
            />
          )}
          {product?.productType === 'book' && <OptionBlock option={numberOfPhotosOptions} />}
          {!!product.options?.anyStringOptions?.length &&
            product.options.anyStringOptions.map((option) => (
              <OptionBlock option={option} key={option.id} />
            ))}
        </div>

        {showDeliveryCalculator && (
          <button className={styles.widgetLink} onClick={scrollToTarget} type="button">
            Рассчитать срок получения заказа
          </button>
        )}

        <div className={styles.getProduct} ref={targetRef}>
          <div className={styles.actionButtonsContainer}>
            <BuyButton
              optionSalePrice={optionSalePrice || null}
              realPrice={optionPrice}
              isProductUnavailable={product.isUnavailable}
              isPreorder={product.preOrder}
              onClick={product.preOrder ? handleRedirectToPreorder : handleRedirectToConstructor}
              buttonTitle={product.preOrder ? 'Оформить предзаказ' : product.actionTitle}
              productUrl={productUrl}
            />
          </div>

          {!product.preOrder && (
            <div className={styles.actionServicesContainer}>
              {product?.productType === 'book' && (
                <OrderServiceButton
                  realPrice={optionPrice}
                  isPageCountInitial={pageCount === initialProductPageCount}
                  isProductUnavailable={product.isUnavailable}
                  onClick={handleRedirectToDesign}
                  buttonType="design"
                />
              )}
              {!['postcard', 'wrappingPaper'].includes(product?.productType) && (
                <OrderServiceButton
                  realPrice={optionPrice}
                  isPageCountInitial={pageCount === initialProductPageCount}
                  isProductUnavailable={product.isUnavailable}
                  onClick={handleRedirectToGift}
                  buttonType={product?.productType === 'book' ? 'gift' : 'onlyGift'}
                />
              )}
            </div>
          )}
        </div>

        {!product.isUnavailable && (
          <div className={styles.splitWrapper}>
            <SplitButton splitSum={splitPrice} />
          </div>
        )}
        <ProductInfo productDescription={product.description} />

        <Promos promos={product.promos} />

        {showDeliveryCalculator && (
          <DeliveryWidget productType={product.innerType} productSize={productSize} />
        )}

        <MiniServices
          customServices={product.services}
          eventPrefix="productcard"
          hasGoogleEvent
          mini
        />
        <StickyDesktopInfo
          productTitle={product?.title}
          productSubtitle={productInfoSubtitle}
          gatsbyImage={product.image?.localFile}
          targetRef={targetRef}
        >
          <>
            {!product?.preOrder && product?.productType === 'book' && (
              <OrderServiceButton
                realPrice={optionPrice}
                isPageCountInitial={pageCount === initialProductPageCount}
                isProductUnavailable={product.isUnavailable}
                onClick={handleRedirectToDesign}
                buttonType="design"
                inPlate
              />
            )}
            <BuyButton
              optionSalePrice={optionSalePrice}
              realPrice={optionPrice}
              isProductUnavailable={product.isUnavailable}
              isPreorder={product.preOrder}
              onClick={product.preOrder ? handleRedirectToPreorder : handleRedirectToConstructor}
              buttonTitle={product.preOrder ? 'Оформить предзаказ' : product.actionTitle}
              productUrl={productUrl}
              inPlate
            />
          </>
        </StickyDesktopInfo>
      </div>
    </div>
  );
}
